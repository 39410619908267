import axios from 'axios';
import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';

const siteKeys = {
	v2: process.env.REACT_APP_CAPTCHA_KEY_V2 || '6Ld4L3gqAAAAALTtyfm8Qc6E0jAa_5MKZRH0cZMw',
	v3: process.env.REACT_APP_CAPTCHA_KEY_V3 || '6Lf1OXYqAAAAAMOgFny9L4cBz47bOiIYf-JtTYv8',
};

type ButtonProps = {
	setRecaptchaToken: (token: string) => void;
	setIsRecaptchaEnabled: (value: boolean) => void;
	action: string;
};
const CaptchaButton = ({ setRecaptchaToken, setIsRecaptchaEnabled, action }: ButtonProps) => {
	const { t, ...options } = useTranslation();
	const [isEnabled, setIsEnabled] = useState(false);
	const [recaptchaVersion, setRecaptchaVersion] = useState('');

	useEffect(() => {
		axios
			.get(`${API_ENDPOINTS.getRecaptchaConfig}/${action}`)
			.then((response) => {
				setIsEnabled(response.data.enabled);
				setIsRecaptchaEnabled(response.data.enabled);
				setRecaptchaVersion(response.data.version ? response.data.version.toLocaleLowerCase() : '');
			})
			.catch((err) => console.error(err));
	}, []);

	useEffect(() => {
		const script = document.createElement('script');
		const recaptcha = document.querySelector('.grecaptcha-badge') as HTMLElement | null;
		document.body.appendChild(script);
		if (script) {
			if (recaptchaVersion === 'v3' && isEnabled) {
				if (recaptcha) {
					recaptcha.style.display = 'block';
				}
				script.src = `https://www.google.com/recaptcha/api.js?render=${siteKeys.v3}`; // Use your site key here
				script.onload = () => {
					if (window.grecaptcha) {
						window.grecaptcha.ready(() => {
							window.grecaptcha
								.execute(siteKeys.v3, { action: 'submit' })
								.then((token: string) => {
									setRecaptchaToken(token);
									console.log('v3 Token:', token);
								})
								.catch((err) => console.error('Error executing v3 ReCAPTCHA:', err));
						});
					}
				};
			} else {
				document.body.removeChild(script);
			}
		}
		return () => {
			if (recaptcha) {
				recaptcha.style.display = 'none';
			}
		};
	}, [isEnabled, recaptchaVersion]);

	const handleRecaptchaChange = (value: string | null) => {
		if (value) {
			setRecaptchaToken(value);
		}
	};
	return (
		<>
			{recaptchaVersion === 'v2' && isEnabled ? (
				<ReCAPTCHA
					sitekey={siteKeys.v2}
					onChange={handleRecaptchaChange}
					hl={options.i18n.language}
					key={options.i18n.language}
				/>
			) : null}
		</>
	);
};

export default CaptchaButton;
