import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { SignInValues } from '../../models/SignIn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { deleteUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import { useMainContext } from '../../store/MainContext';
import { resetMainState } from '../../assets/config/initial-main-state';
import './SignIn.scss';
import { encryptData } from '../../helpers/passwordEncryptionHandler';
import { TermsAndCondtions } from './TermsAndConditions';
import { useConfigurationContext } from 'store/configurationContext';

import CaptchaButton from 'components/Button/CaptchaButton';

function SignIn() {
	const { t, ...options } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const [signInFormLoading, setSignInFormLoading] = React.useState(false);
	const [signInFormDisabled, setSignInFormDisabled] = React.useState(true);
	const [showTerms, setShowTerms] = React.useState(false);
	const [acceptTerms, setAccetTerms] = React.useState(false);
	const [recaptcha, setRecaptcha] = React.useState('');
	const [isEnabled, setIsEnabled] = React.useState(false);

	const { configurationState } = useConfigurationContext();

	const currentYear = React.useMemo(() => new Date().getFullYear(), []);

	const backgroundImage = configurationState.find((item) => item.configKey === 'background_image')?.value || '';
	const companyWebsite = configurationState.find((item) => item.configKey === 'company_website')?.value || '';

	const onSubmit = async (signInValues: SignInValues) => {
		setSignInFormLoading(true);
		const encryptedPassword = encryptData(signInValues.password, signInValues.email.toLocaleLowerCase());
		// Construct headers conditionally
		const headers: Record<string, string> = {};

		if (isEnabled && recaptcha) {
			headers['RECAPTCHA-TOKEN'] = recaptcha;
		}
		axios
			.post(
				API_ENDPOINTS.signin,
				{
					...signInValues,
					email: signInValues.email.toLocaleLowerCase(),
					password: encryptedPassword,
					termsAccepted: acceptTerms,
				},
				{
					headers: {
						'Content-Type': 'application/json', // Example header
						...headers,
					},
				},
			)
			.then((res) => {
				const userData = res.data;
				setMainState({
					...mainState,
					email: userData?.email,
					fullName: userData?.fullName,
					isLoggedIn: true,
					isMerchant: userData?.isMerchant,
					token: userData?.token,
					countryCode: userData?.countryCode,
					userStatus: userData?.userStatus,
				});
				setUserInfo(res.data);
				setSignInFormLoading(false);
				navigate('/merchants');
			})
			.catch((err) => {
				deleteUserInfo();
				setMainState(resetMainState);
				setSignInFormLoading(false);
				if (err.response?.data.code === 2043) {
					setShowTerms(true);
				}
				// if (err.response?.data.code === 2000) {
				// 	window.location.replace(sessionExpiredUrl);
				// }
			});
	};

	const onFormChange = (_: any, signInValues: SignInValues) => {
		setSignInFormDisabled(
			!EMAIL_VALIDATION.test(signInValues?.email) || !PASSWORD_VALIDATION.test(signInValues?.password),
		);
	};

	const sessionExpiredUrl = configurationState.find((item) => item.configKey === 'session_expired_url')?.value || '';
	if (
		sessionExpiredUrl &&
		window.location.pathname.includes('sign-in') &&
		!sessionExpiredUrl.includes(window.location.hostname)
	) {
		window.location.replace(sessionExpiredUrl);
	}
	return (
		<>
			<section className='signin-section' style={{ backgroundImage: `url(${backgroundImage})` }}>
				<div className='form-container w-100 sm:w-[420px] m-5'>
					<h1 className='text-center m-0 my-3 text-sm font-normal text-[#757575] '>{t<string>('welcomeBack')}👋</h1>
					<p className='text-center m-0 my-4 text-2xl font-bold text-[#0f2147]'>{t<string>('loginToYourAccount')}</p>
					<Form
						name='basic'
						layout='vertical'
						onFinish={onSubmit}
						onValuesChange={onFormChange}
						autoComplete='off'
						requiredMark='optional'
					>
						<Form.Item
							label={t<string>('email')}
							name='email'
							rules={[
								{ required: true, message: t<string>('emailRequired') },
								{ pattern: EMAIL_VALIDATION, message: t<string>('emailMsg') },
							]}
						>
							<Input placeholder={t<string>('pleaseEnterYourEmail')} style={{ direction: 'ltr' }} />
						</Form.Item>
						<Form.Item
							label={t<string>('password')}
							name='password'
							rules={[
								{ required: true, message: t<string>('passwordRequired') },
								{
									pattern: PASSWORD_VALIDATION,
									message: t<string>('passwordMsg'),
								},
							]}
						>
							<Input.Password placeholder={t<string>('enterPassword')} className='ltr-input' />
						</Form.Item>
						<Form.Item>
							<Button
								className='form-btn'
								type='primary'
								htmlType='submit'
								disabled={signInFormDisabled}
								loading={signInFormLoading}
							>
								{t<string>('signIn')}
							</Button>
						</Form.Item>
						<div className='text-xs text-center my-2 text-[#0f2147]'>
							{t<string>('notRegistered')}{' '}
							<span className='text-xs text-[#00afff] underline'>{t<string>('createAnAccount')}</span> {t<string>('or')}{' '}
							<span className='text-xs text-[#00afff] underline'>{t<string>('becomeMerchant')}</span>
						</div>
						<div className='text-sm text-center my-2 text-[#0f2147]'>
							{t<string>('readOur')}{' '}
							<span className='text-xs text-[#00afff] underline'>{t<string>('termsAndConditions')}</span>
						</div>
						<p className='warning-note'>{t<string>('signinWarningNote')}</p>
						<p className='all-rights-reserved'>
							© {currentYear} {companyWebsite}. {t<string>('allRightsReserved')}
						</p>
					</Form>
				</div>
				<CaptchaButton setRecaptchaToken={setRecaptcha} setIsRecaptchaEnabled={setIsEnabled} action='LOGIN' />
			</section>
			{<TermsAndCondtions showTerms={showTerms} setShowTerms={setShowTerms} isAccetingTerms={setAccetTerms} />}
		</>
	);
}

export default SignIn;
