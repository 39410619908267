import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../store/MainContext';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { OfferType } from '../../models/MyOrders';
import { Button, Dropdown, Form, Input, MenuProps, Select, Table, DatePicker, Grid } from 'antd';
import Buy from '../../assets/images/icons/Buy';
import Sell from '../../assets/images/icons/Sell';
import { getMyOrderColumns } from '../../helpers/myOrdersTableColumns';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './MyOrders.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import { getUserInfo } from '../../helpers/localStorageHandler';

import { CaretDownOutlined, SyncOutlined, SearchOutlined } from '@ant-design/icons';
import { StyledPagination } from 'components/table.style';
import { useConfigurationContext } from 'store/configurationContext';
const DATE_FORMAT = 'YYYY-MM-DD';
function MyOrders() {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const [searchParams, setSearchParam] = useSearchParams();
	const { mainState, setMainState } = useMainContext();
	const [offerType, setOfferType] = useState<OfferType.buy | OfferType.sell | any>(
		searchParams.get('offerType') || OfferType.buy,
	);
	const [myOrdersLoading, setMyOrdersLoading] = useState(false);
	const [myOrders, setMyOrders] = useState([]);
	const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') || '');
	const [pageSize, setPageSize] = useState(10);
	const [orderStatusFilter, setOrderStatusFilter] = useState(searchParams.get('orderStatusFilter') || 'All');
	const [startDateFilter, setStartDateFilter] = useState<string>(searchParams.get('startDateFilter') || '');
	const [endDateFilter, setEndDateFilter] = useState<string>(searchParams.get('endDateFilter') || '');
	const [sortBy, setSortBy] = useState('createdAt-desc');
	const [urlForOrders, setUrlForOrders] = useState('');
	const [offerTypeFilter, setOfferTypeFilter] = useState(searchParams.get('offerTypeFilter') || 'All');
	const isMerchant = useMemo(() => mainState?.isMerchant, [mainState?.isMerchant]);
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);
	const navigate = useNavigate();
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const token = getUserInfo()?.token;
	const [searchValue, setSearchValue] = useState('');

	const { RangePicker } = DatePicker;
	const { configurationState } = useConfigurationContext();

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		if (orderStatusFilter) {
			orderStatusFilter !== 'All'
				? params.set('orderStatusFilter', orderStatusFilter)
				: params.delete('orderStatusFilter');
			setSearchParam(params);
		}
		if (offerType) {
			params.set('offerType', offerType);
			setSearchParam(params);
		}
		if (offerTypeFilter) {
			orderStatusFilter !== 'All' ? params.set('offerTypeFilter', offerTypeFilter) : params.delete('offerTypeFilter');
			setSearchParam(params);
		}
		if (startDateFilter) {
			startDateFilter !== '' ? params.set('startDateFilter', startDateFilter) : params.delete('startDateFilter');
			setSearchParam(params);
		}
		if (endDateFilter) {
			endDateFilter !== '' ? params.set('endDateFilter', endDateFilter) : params.delete('endDateFilter');
			setSearchParam(params);
		}
		if (searchValue) {
			searchValue !== '' ? params.set('searchValue', searchValue) : params.delete('search');
			setSearchParam(params);
		}
	}, [orderStatusFilter, offerTypeFilter, startDateFilter, searchValue, endDateFilter, offerType]);

	const changeOageSize = (pageSize: number) => {
		setPage(0);
		setPageSize(pageSize);
	};

	const pageSizeItems: MenuProps['items'] = [
		{ label: '10', key: '1', onClick: () => changeOageSize(10) },
		{ label: '20', key: '2', onClick: () => changeOageSize(20) },
		{ label: '50', key: '3', onClick: () => changeOageSize(50) },
		{ label: '100', key: '4', onClick: () => changeOageSize(100) },
	];
	const isDev = window.location.hostname.includes('devb');
	useEffect(() => {
		setMyOrdersLoading(true);
		if (!mainState.isLoggedIn && !Boolean(token)) {
			setMainState({ ...mainState, isLoggedIn: false });
		} else {
			let orderStatus =
				orderStatusFilter !== 'ALL' && orderStatusFilter !== 'All' && orderStatusFilter !== ''
					? `status%3A${orderStatusFilter}`
					: '';

			let startDate =
				startDateFilter && !endDateFilter
					? `createdAt%3E${dayjs(startDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
					: '';
			let endDate =
				!startDateFilter && endDateFilter
					? `createdAt%3C${dayjs(endDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
					: '';
			let dateRange =
				startDateFilter && endDateFilter
					? `createdAt%3E${dayjs(startDateFilter)
							.toISOString()
							.replace(/T/, ' ')
							.replace(/\..+/, '')}%2CcreatedAt%3C${dayjs(endDateFilter)
							.toISOString()
							.replace(/T/, ' ')
							.replace(/\..+/, '')}`
					: '';
			let search = searchTerm ? `&wildSearch=${searchTerm}` : '';
			let sort = '';
			if (sortBy.startsWith('merchants')) {
				sort = `&sort=merchant%23user%23fullName-${sortBy.split('-')[1]}`;
			} else {
				sort = `&sort=${sortBy}`;
			}

			let type = '';
			const offerTypeValue = isMerchant
				? offerTypeFilter === 'All'
					? ''
					: offerTypeFilter
				: offerType === OfferType.buy
				? 'BUY'
				: 'SELL';
			if (offerTypeValue) {
				type = `type%3A${offerTypeValue}`;
			}

			let filter;
			if (!orderStatus && !type && !startDate && !endDate && !dateRange) {
				filter = '';
			} else {
				filter = `search=${orderStatus}%2C${type}%2C${startDate}%2C${endDate}%2C${dateRange}&`;
			}
			const urlForOrders = `${
				isMerchant ? API_ENDPOINTS.merchantOrderList : API_ENDPOINTS.clientOrderList
			}?${filter}page=${page}&pageSize=${pageSize}${sort}${search}`;

			setUrlForOrders(urlForOrders);

			axios
				.get(urlForOrders, { headers: { Authorization: `Bearer ${token}` } })
				.then((result) => {
					setMyOrders(result.data.data);
					setTotalElements(result.data.totalElements);
					setMyOrdersLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setMyOrdersLoading(false);
				});
		}
	}, [
		orderStatusFilter,
		endDateFilter,
		startDateFilter,
		offerType,
		pageSize,
		searchValue,
		sortBy,
		page,
		offerTypeFilter,
	]);

	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [orderStatusFilter, endDateFilter, startDateFilter, offerType, searchValue, offerTypeFilter]);
	const onOfferTypeBtnClick = (offerType: OfferType) => setOfferType(offerType);

	const refreshData = () => {
		axios
			.get(urlForOrders, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => {
				setMyOrders(result.data.data);
				setMyOrdersLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setMyOrdersLoading(false);
			});
	};

	const clearFilters = () => {
		setOrderStatusFilter('All');
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchTerm('');
		setSortBy('createdAt-desc');
		setEndDateFilter('');
	};
	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div className='orders-container'>
			<SubHeader title={t<string>('myOrders')} description={t<string>('myOrderSubtitle')} />
			<div className='min-h-[70vh] max-w-[1440px] mx-auto px-[20px] lg:px-[75px]  pb-10 bg-white xss:pt-[20px] sm:pt-[30px]'>
				<div className='flex items-center justify-between mb-3 my-1 btn-container'>
					{isMerchant ? (
						<div />
					) : (
						<div className='flex bg-[#F5F5F5] rounded-[4px] px-[3px]'>
							<Button
								onClick={() => {
									onOfferTypeBtnClick(OfferType.buy);
									clearFilters();
								}}
								className={`flex items-center justify-center w-auto h-[34px] border-none text-sm ${
									offerType === OfferType.buy
										? 'text-white bg-green-500 hover:!text-white'
										: 'bg-transparent text-black hover:!text-black'
								}`}
							>
								<span className='mr-[6px] inline-block mx-2'>
									<Buy active={offerType === OfferType.buy} />
								</span>
								{isDev ? t<string>('buy') : t<string>('deposit')}
							</Button>
							<Button
								onClick={() => {
									onOfferTypeBtnClick(OfferType.sell);
									clearFilters();
								}}
								className={`flex items-center justify-center w-auto h-[34px] border-none text-sm ${
									offerType === OfferType.sell
										? 'text-white bg-red-500 hover:!text-white'
										: 'bg-transparent text-black hover:!text-black'
								}`}
							>
								<span className='mr-[6px] inline-block mx-2'>
									<Sell active={offerType === OfferType.sell} />
								</span>
								{isDev ? t<string>('sell') : t<string>('withdrawal')}
							</Button>
						</div>
					)}
				</div>
				<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 px-4 mb-6 border border-solid border-gray-200'>
					<Form layout='vertical'>
						<div className='flex flex-col md:flex-row gap-4'>
							{isMerchant && (
								<Form.Item label={t<string>('offerType')} className='xss:w-full md:w-[300px] my-0'>
									<Select defaultValue={''} value={offerTypeFilter} onChange={(e: string) => setOfferTypeFilter(e)}>
										{[
											{ TypeName: t<string>('all'), value: 'All' },
											{ TypeName: isDev ? t<string>('buy') : t<string>('deposit'), value: 'BUY' },
											{ TypeName: isDev ? t<string>('sell') : t<string>('withdrawal'), value: 'SELL' },
										].map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.TypeName}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}
							<Form.Item label={t<string>('status')} className='xss:w-full md:w-[300px]  my-0'>
								<Select
									defaultValue={'Active'}
									value={orderStatusFilter}
									onChange={(e: string) => setOrderStatusFilter(e)}
								>
									{[
										{ id: 0, status: t<string>('all'), key: 'ALL' },
										{ id: 1, status: t<string>('placed'), key: 'PLACED' },
										{ id: 2, status: t<string>('opened'), key: 'OPENED' },
										{ id: 3, status: t<string>('transferred'), key: 'TRANSFERRED' },
										{ id: 4, status: t<string>('completed'), key: 'COMPLETED' },
										{ id: 5, status: t<string>('cancelled'), key: 'CANCELLED' },
										{ id: 6, status: t<string>('expired'), key: 'EXPIRED' },
										{ id: 7, status: t<string>('appealed'), key: 'APPEAL' },
										{ id: 8, status: t<string>('rejected'), key: 'REJECTED' },
									].map((item, index) => {
										return (
											<Select.Option key={index} value={item.key} onChange={() => setOrderStatusFilter(item.key)}>
												{item.status}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
							<Form.Item label={t<string>('dateRange')} className='xss:w-full md:w-[300px]  my-0'>
								{!startDateFilter && !endDateFilter && (
									<RangePicker
										className='m-0 w-full'
										placeholder={[t<string>('startDate'), t<string>('endDate')]}
										onChange={(values) => {
											if (values && values.length === 2) {
												const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
												const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
												setStartDateFilter(startDate);
												setEndDateFilter(endDate);
											} else {
												setStartDateFilter('');
												setEndDateFilter('');
											}
										}}
									/>
								)}
								{startDateFilter && endDateFilter && (
									<RangePicker
										className='m-0 w-full'
										placeholder={[t<string>('startDate'), t<string>('endDate')]}
										value={[dayjs(startDateFilter), dayjs(endDateFilter)]}
										onChange={(values) => {
											if (values && values.length === 2) {
												const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
												const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
												setStartDateFilter(startDate);
												setEndDateFilter(endDate);
											} else {
												setStartDateFilter('');
												setEndDateFilter('');
											}
										}}
									/>
								)}
							</Form.Item>
						</div>
					</Form>
				</div>
				<div className='mb-4 items-center justify-between w-full  flex flex-col md:flex-row-reverse flex-wrap my-2'>
					<div className='flex md:w-1/4 w-full'>
						<Button
							onClick={refreshData}
							className='h-10 w-10 mr-2 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
						>
							<SyncOutlined />
						</Button>
						<Input
							id='my-orders_search_input'
							className='border-none h-10 bg-[#F5F5F5] search-input'
							placeholder={t<string>('searchOrder')}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							size='large'
							prefix={<SearchOutlined />}
						/>
					</div>

					<div className='w-full md:w-[75%] flex sm:justify-between md:flex-row xss:justify-start'>
						<div className='flex  md:w-1/4 items-center my-2'>
							<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
							<Dropdown className='mx-[4px] md:mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
								<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
									{pageSize}
									<CaretDownOutlined />
								</span>
							</Dropdown>
							<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
						</div>
					</div>
				</div>
				<StyledPagination bgColor={themeColor} color={secondary_color}>
					<Table
						columns={getMyOrderColumns(t, sortBy, setSortBy, isMerchant, !lg)}
						dataSource={myOrders}
						loading={myOrdersLoading}
						pagination={{
							pageSize,
							total: totalElements,
							onChange: (pageIndex) => setPage(pageIndex - 1),
							current: page + 1,
						}}
						tableLayout='fixed'
					/>
				</StyledPagination>
			</div>
		</div>
	);
}

export default MyOrders;
