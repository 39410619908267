import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { MyOrder, OfferType } from '../models/MyOrders';
import './myOrdersTableColumns.scss';
import dayjs from 'dayjs';
import { CaretDownOutlined, CaretUpOutlined, EyeOutlined, WechatOutlined } from '@ant-design/icons';
import { TableColumnType, Tooltip } from 'antd';
import { getStateConfiguration } from '../pages/OrderLifeCycle/helpers/getStateConfiguration';
import { OrderStateType } from 'pages/OrderLifeCycle/types/OrderStateType';
import { handleSortClick } from './sorterColumn';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const getMyOrderColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	isMerchant: boolean,
	isMobile: boolean,
) => {
	let dataIndex, key;
	if (isMerchant) {
		dataIndex = 'client';
		key = 'client';
	} else {
		dataIndex = 'merchant';
		key = 'merchant';
	}
	const isDev = window.location.hostname.includes('devb');
	const getType = (value: string) => {
		if (isDev) {
			return value?.toLocaleLowerCase() === OfferType.buy ? t<string>('buy') : t<string>('sell');
		} else {
			return value?.toLocaleLowerCase() === OfferType.buy ? t<string>('deposit') : t<string>('withdrawal');
		}
	};
	const columns: ColumnsType<MyOrder> = [
		{
			title: <div className='text-[#000D1D99] text-sm'>{t<string>('visit')}</div>,
			dataIndex: 'orderNumber',
			responsive: ['lg'],
			ellipsis: true,
			width: 50,
			render: (_value, record, i) => {
				return (
					<Link
						id={`my-orders_view-order_${i}`}
						to={`/order-life-cycle?id=${record.orderNumber}`}
						className='text-xs text-[#01081E99] flex items-center  text-[#0ea5e9] underline'
					>
						<Tooltip placement='top' title={t<string>('Seeorderdetails')}>
							<EyeOutlined style={{ fontSize: 20 }} />
						</Tooltip>
					</Link>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('quantity')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'quantity-asc'))}
							style={{ color: sortBy === 'quantity-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'quantity-desc'))}
							style={{ color: sortBy === 'quantity-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'quanity',
			key: 'quanity',
			render: (value, record, i) => (
				<div id={`my-orders_quantity_${i}`} className='text-lightBlue text-base'>
					{record.quantity?.toLocaleString()}
				</div>
			),
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('amount')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'amount-desc'))}
							style={{ color: sortBy === 'amount-desc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'amount-asc'))}
							style={{ color: sortBy === 'amount-asc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'amount',
			key: 'amount',
			render: (value, record, i) => (
				<div id={`my-orders_amount_${i}`} className=' text-lightBlue text-base '>
					{record.amount?.toLocaleString()}
				</div>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('price')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'price-asc'))}
							style={{ color: sortBy === 'price-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'price-desc'))}
							style={{ color: sortBy === 'price-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'available',
			key: 'available',
			render: (value, record, i) => {
				return (
					<div id={`my-orders_available_${i}`} className=' text-lightBlue text-base'>
						{record.price}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{isMerchant ? <>{t<string>('client')}</> : <>{t<string>('merchants')}</>}
					<div className='flex flex-col mx-2'>
						{isMerchant ? (
							<>
								<CaretUpOutlined
									onClick={() => setSortBy(handleSortClick(sortBy, 'client-asc'))}
									style={{ color: sortBy === 'client-asc' ? 'black' : 'gray' }}
								/>
								<CaretDownOutlined
									onClick={() => setSortBy(handleSortClick(sortBy, 'client-desc'))}
									style={{ color: sortBy === 'client-desc' ? 'black' : 'gray' }}
								/>
							</>
						) : (
							<>
								<CaretUpOutlined
									onClick={() => setSortBy(handleSortClick(sortBy, 'merchants-asc'))}
									style={{ color: sortBy === 'merchants-asc' ? 'black' : 'gray' }}
								/>
								<CaretDownOutlined
									onClick={() => setSortBy(handleSortClick(sortBy, 'merchants-desc'))}
									style={{ color: sortBy === 'merchants-desc' ? 'black' : 'gray' }}
								/>
							</>
						)}
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex,
			key,
			render: (value, record, i) => {
				return (
					<div
						id={`my-orders_fullname_${i}`}
						className='flex items-center bg-[#00afff0d] text-center rounded-md px-2 font-medium text-[#00AFFF] justify-start w-fit'
					>
						{isMerchant ? (
							<div className='text-sm'>{record?.clientFullName}</div>
						) : (
							<Link to={`/merchant/?id=${record?.merchantId}`} className='flex text-[#0ea5e9] underline text-sm pe-2'>
								{record?.merchantFullName}
							</Link>
						)}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('type')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'type-asc'))}
							style={{ color: sortBy === 'type-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'type-desc'))}
							style={{ color: sortBy === 'type-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'type',
			key: 'type',
			render: (value, record, i) => {
				return (
					<div
						id={`my-orders_type_${i}`}
						className='font-medium text-base'
						style={{ color: record.type === 'BUY' ? 'green' : 'red' }}
					>
						{getType(record.type)}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('status')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-desc'))}
							style={{ color: sortBy === 'status-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-desc'))}
							style={{ color: sortBy === 'status-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'status',
			key: 'status',
			render: (value, record, i) => {
				return (
					<div
						id={`my-orders_status_${i}`}
						className='flex items-center flex-nowrap rounded-sm  text-base font-medium text-[#000]'
						style={{ color: getStateConfiguration(record.status as OrderStateType, t)?.color }}
					>
						<div
							style={{
								width: '8px',
								height: '8px',
								borderRadius: '100px',
								marginInlineEnd: '5px',
								backgroundColor: getStateConfiguration(record.status as OrderStateType, t)?.color,
							}}
						/>
						{t<string>(record.status.toLocaleLowerCase()) as OrderStateType}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] text-sm flex items-center cursor-pointer'>
					{t<string>('dateAndTime')}
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-asc'))}
							style={{ color: sortBy === 'createdAt-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-desc'))}
							style={{ color: sortBy === 'createdAt-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			responsive: ['lg'],
			dataIndex: 'dateAndTime',
			key: 'dateAndTime',
			render: (value, record, i) => {
				return (
					<div id={`my-orders_dateandtime_${i}`} className='text-[#01081E99] text-base '>
						{dayjs(record.createdAt).format(DATE_TIME_FORMAT)}
					</div>
				);
			},
		},
		...(isMobile
			? [
					{
						dataIndex: 'record',
						key: 'record',
						className: 'small-screen-row',
						render: (value, record, i) => {
							return (
								<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
									<div className='w-full'>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('visit')}:</div>
											<Link
												to={`/order-life-cycle?id=${record.orderNumber}`}
												className=' mx-1 text-xs text-[#01081E99] flex items-center text-[#0ea5e9] underline'
											>
												<Tooltip placement='top' title={t<string>('seeOrderDetails')}>
													<EyeOutlined style={{ fontSize: 20 }} />
												</Tooltip>
											</Link>
										</div>

										{/* TODO: change the curency if needed to local currency */}
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('quantity')}:</div>
											<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
												{record.quantity?.toLocaleString()}
												<span className='inline-block mx-1 text-[grey] text-[11px]'></span>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('amount')}:</div>
											<div className='text-[15px] font-medium text-lightBlue flex items-center'>
												{record.amount?.toLocaleString()}
												<span className='inline-block mx-1 text-[grey] text-[11px]'></span>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('price')}:</div>
											<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
												{record.price}
												<span className='inline-block mx-1 text-[grey] text-[11px]'></span>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('status')}:</div>
											<div
												className='flex items-center  flex-wrap rounded-sm text-[14px] font-medium text-[#000] px-2 '
												style={{ color: getStateConfiguration(record.status as OrderStateType, t)?.color }}
											>
												<div
													style={{
														width: '8px',
														height: '8px',
														borderRadius: '100px',
														marginInline: '5px',
														backgroundColor: getStateConfiguration(record.status as OrderStateType, t)?.color,
													}}
												/>
												{t<string>(record.status.toLocaleLowerCase()) as OrderStateType}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>(isMerchant ? 'client' : 'merchant')}:</div>
											<div className='flex flex-row items-center'>
												<>
													{isMerchant ? (
														<div className='items-center bg-[#00afff0d] rounded-md text-[16px] font-medium text-[#00AFFF] px-2  justify-content-center inline-flex'>
															<span className='text-xs text-[#01081E99] flex items-center mb-1 text-[#0ea5e9] mx-2'>
																{record?.clientFullName}
															</span>
															<WechatOutlined />
														</div>
													) : (
														<div className='items-center bg-[#00afff0d] rounded-md text-[16px] font-medium text-[#00AFFF] px-2  justify-content-center inline-flex'>
															<Link
																to={`/merchant/?id=${record?.merchantId}`}
																className='text-xs text-[#01081E99] flex items-center mx-2 mb-1 text-[#0ea5e9] underline'
															>
																{record?.merchantFullName}
															</Link>
															<WechatOutlined />
														</div>
													)}
												</>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('Date&Time')}:</div>
											<div className='text-xs text-[#01081E99] flex items-center '>
												{dayjs(record.createdAt).format(DATE_TIME_FORMAT)}
											</div>
										</div>
									</div>
								</div>
							);
						},
					} as TableColumnType<MyOrder>,
			  ]
			: []),
	];

	if (isMerchant) {
		return columns;
	} else {
		return columns.filter((column: any) => column.dataIndex !== 'type');
	}
};
