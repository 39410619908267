import { Col, ConfigProvider, Row } from 'antd';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import axios from 'axios';
import { getUserInfo } from 'helpers/localStorageHandler';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubHeader from '../../components/SubHeader/SubHeader';
import { WalletActions } from './components/WalletActions';
import { WalletAmount } from './components/WalletAmount';
import { WalletFilters } from './components/WalletFilters';
import { WalletTable } from './components/WalletTable';
import { themeConfig } from './config';
import { Wallet as IWallet } from './types/Wallet';
import { WalletTransactionsResponse } from './types/WalletTransactionsResponse';
import { WalletActionType } from './types/walletActionType';
import { WithdrawDepositModal } from './modals/WithdrawDepositModal';
import { MyAccount } from 'models/MyAccounts';
import { useLocation } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';
import { useNotificationTypeContext } from 'store/NotificationTypeContext';
import { NotificationActionType } from 'helpers/getNotificationsActionTypes';

const isValueInvalid = (value: unknown): boolean =>
	value !== undefined ||
	value !== null ||
	value !== '' ||
	value !== 'ALL' ||
	(Array.isArray(value) && (value as []).some(isValueInvalid));

export const Wallet = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const { notificationType, setNotificationType } = useNotificationTypeContext();
	const queryParams = new URLSearchParams(location.search);

	// Table State
	const [data, setData] = useState<IWallet[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [numberOfEntries, setNumberOfEntries] = useState('20');
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);

	// Filters State
	const [transactionStatusList, setTransactionStatusList] = useState([]);
	const [transactionTypeList, setTransactionTypeList] = useState<{ [K: string]: string[] }>({});
	const [search, setSearch] = useState('');
	const [status, setStatus] = useState<string | null>(null);
	const [type, setType] = useState<string | null>(null);
	const [dateRange, setDateRange] = useState<string[]>([]);
	const [isP2P, setIsP2P] = useState(false);
	const [orderNumber, setOrderNumber] = useState<string | null>(queryParams.get('id') ?? '');
	const [amount, setAmount] = useState('0.00 USD');
	const [walletActionType, setWalletActionType] = useState<WalletActionType | string>('');
	const [open, setOpen] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const [operation, setOperation] = useState<string | null>(queryParams.get('operation') ?? null);
	const [accounts, setAccounts] = useState<MyAccount[]>([]);
	const [account, setAccount] = useState<MyAccount | null>(null);
	const [sortBy, setSortBy] = useState('createdAt-desc');
	const walletsNumber = queryParams.get('walletNumber') ?? '';

	const searchParameter = useMemo(
		() =>
			[
				['createdAt', dateRange],
				['state', status],
				['operation', operation],
				['type', type],
				['order%23orderNumber', orderNumber],
				['tradingAccount%23tpAccountNumber', account?.number],
				['wallet%23walletNumber', walletsNumber],
			]
				.filter(([key, value]) => {
					if (key === 'createdAt') return Boolean(value?.length);
					return Boolean(value);
				})
				.map(([key, value]) =>
					key === 'createdAt'
						? `createdAt%3E${value?.[0]}%2CcreatedAt%3C${dayjs(value?.[1]).format('YYYY-MM-DD 23:59:00')}`
						: `${key}%3A${value}`,
				)
				.join('%2C'),
		[dateRange, orderNumber, status, account, type, operation],
	);

	const params = useMemo(
		() =>
			[
				['page', page],
				['pageSize', numberOfEntries],
				['sort', sortBy],
			]
				.filter(([, value]) => !!value)
				.reduce(
					(params, [key, value]) => ({
						...params,
						[key as string]: value,
					}),
					{},
				),
		[numberOfEntries, page, sortBy],
	);

	const token = getUserInfo()?.token;

	const refresh = useCallback(() => {
		setNumberOfEntries('10');
		setSearch('');
		setStatus(null);
		setType(null);
	}, [setNumberOfEntries, setSearch, setStatus, setType]);

	useEffect(() => {
		Promise.all([
			axios.get(`${API_ENDPOINTS.walletTransactions}/status`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(`${API_ENDPOINTS.walletTransactions}/types`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(API_ENDPOINTS.myWallets, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } }),
		])
			.then(([statuses, types, wallets, accounts]) => [statuses?.data, types?.data, wallets.data, accounts.data])
			.then(([statuses, types, wallets, accounts]) => {
				setTransactionStatusList(statuses.map((status: string) => ({ label: t(status), value: status })));
				setTransactionTypeList(types);
				setAmount(`${wallets[0].balance} ${wallets[0].currency}`);
				setAccounts(accounts);
			});
	}, [t, token, open, refetch]);

	useEffect(() => {
		setLoading(true);
		let searchParams = '';
		if (searchParameter) {
			searchParams = `?search=${searchParameter}`;
		}
		if (search) {
			searchParams += searchParams ? `&wildSearch=${search}` : `?wildSearch=${search}`;
		}
		axios
			.get<WalletTransactionsResponse>(`${API_ENDPOINTS.walletTransactions}${searchParams}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...params,
				},
			})
			.then((response) => {
				setData(response?.data?.data || []);
				setTotalElements(response?.data?.totalElements);
				setNotificationType('');
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false);
			});
	}, [params, searchParameter, token, search, open, refetch]);

	useEffect(() => {
		if (notificationType === NotificationActionType.REQUEST_ACTION) {
			setRefetch(!refetch);
		}
	}, [notificationType]);
	const onAction = (value: WalletActionType) => {
		setWalletActionType(value);
		setOpen(true);
	};
	useEffect(() => {
		if (!open) setWalletActionType('');
	}, [open]);
	return (
		<>
			<ConfigProvider theme={themeConfig}>
				<div>
					<SubHeader title={t('WALLET')} description={t('WALLET_DESCRIPTION')} />
					<div className='min-h-[70vh] max-w-[1440px] mx-auto px-[10px] lg:px-[75px]  xss:pt-[20px] sm:pt-[30px] pb-10 bg-white'>
						{/** Amount & Actions */}
						<Row gutter={[16, 16]} className='mb-4'>
							<Col xs={24} md={12}>
								<WalletAmount amount={amount} />
							</Col>
							<Col xs={24} md={12} className='flex xss:justify-start sm:justify-end'>
								<WalletActions onAction={onAction} walletActionType={walletActionType} />
							</Col>
						</Row>

						{/** Filters */}
						<WalletFilters
							className='mb-4'
							numberOfEntries={numberOfEntries}
							setNumberOfEntries={setNumberOfEntries}
							search={search}
							setDateRange={setDateRange}
							setSearch={setSearch}
							status={status}
							setStatus={setStatus}
							type={type}
							setType={setType}
							refresh={refresh}
							transactionStatusList={transactionStatusList}
							transactionTypeList={transactionTypeList}
							isP2P={isP2P}
							setIsP2P={setIsP2P}
							orderNumber={orderNumber as string}
							setOrderNumber={setOrderNumber}
							setOperation={setOperation}
							operation={operation}
							setAccount={setAccount}
							account={account}
							accounts={accounts}
						/>

						{/** Table */}
						<WalletTable
							data={data}
							loading={loading}
							page={page}
							numberOfEntries={Number(numberOfEntries)}
							setPage={setPage}
							total={totalElements}
							sortBy={sortBy}
							setSortBy={setSortBy}
						/>
					</div>
				</div>
			</ConfigProvider>
			{open && <WithdrawDepositModal open={open} setOpen={setOpen} type={walletActionType} />}
		</>
	);
};
