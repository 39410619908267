import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import { getMenuItems } from '../../helpers/menuItems';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../store/MainContext';

import NavBarIcons from './NavBarIcons';
import { useConfigurationContext } from 'store/configurationContext';

const Navbar = () => {
	const { t } = useTranslation();
	const { mainState } = useMainContext();
	const { configurationState } = useConfigurationContext();

	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState.isLoggedIn]);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const menuItems = React.useMemo(() => getMenuItems(isLoggedIn, isMerchant), [isLoggedIn, isMerchant]);
	const location = useLocation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const companyLogo = configurationState.find((item) => item.configKey === 'company_logo')?.value || '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	return (
		<div style={{ backgroundColor: themeColor, color: secondary_color }}>
			<div className='max-w-[1440px] mx-auto px-[15px] md:px-[75px]  pt-2 flex flex-col md:flex-row items-center justify-between'>
				{/* Logo and Menu Items */}
				<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto'>
					<div className='flex justify-between xss:w-full md:w-auto '>
						{companyLogo && (
							<>
								{!mainState.isLoggedIn ? (
									<Link to='/sign-in'>
										<img src={companyLogo} alt='logo' />
									</Link>
								) : (
									<Link to='/merchants'>
										<img src={companyLogo} alt='logo' />
									</Link>
								)}
							</>
						)}
						{windowWidth < 768 && <NavBarIcons />}
					</div>
					{isLoggedIn && (
						<div className='flex md:items-center md:ml-3 w-[100%] xss:justify-around xss:mb-2 md:mb-0'>
							{menuItems.map((i: any) => (
								<Link
									id={`navbar_link_${i.key}`}
									style={{ color: secondary_color }}
									className={`text-sm font-medium opacity-100 md:mx-5 hover:opacity-70 hover:text-white transition-all duration-300 ${
										location.pathname === `/${i.key}` ? 'underline' : 'no-underline'
									}`}
									to={i.key}
									key={i.key}
								>
									{t(i.label)}
								</Link>
							))}
						</div>
					)}
				</div>
				{windowWidth >= 768 && <NavBarIcons />}
			</div>
		</div>
	);
};

export default Navbar;
