import React from 'react';
import './Navbar.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import Languages from '../Languages/Languages';
import { useMainContext } from '../../store/MainContext';
import { deleteUserInfo, getUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { resetMainState } from '../../assets/config/initial-main-state';
import { getInitials } from '../../helpers/getStringInitials';
import Notification from '../Notification/Notification';
import { Configuration, useConfigurationContext } from 'store/configurationContext';
import { sessionExpiredUrl as defaultsSessionExpiredUrl } from 'constant/fallback-config';

const NavBarIcons = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const { configurationState } = useConfigurationContext();
	const userMenuDetails = React.useMemo(
		() => ({
			email: mainState?.email ?? '',
			fullName: mainState?.fullName ?? '',
			countryCode: mainState?.countryCode ?? '',
		}),
		[mainState.email, mainState.fullName, mainState?.countryCode],
	);
	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState.isLoggedIn]);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	const userInfo = getUserInfo();
	const hanelLogOut = React.useCallback(() => {
		const token = userInfo?.token;
		if (token) {
			axios.post(API_ENDPOINTS.logout, { token }, { headers: { Authorization: `Bearer ${token}` } }).then(async (_) => {
				deleteUserInfo();
				setMainState(resetMainState);
				const result = await axios.post(API_ENDPOINTS.getGeneralConfig, { keys: ['session_expired_url'] });
				const sessionExpiredUrl =
					result.data.find((item: Configuration) => item.configKey === 'session_expired_url')?.value || '';
				if (sessionExpiredUrl) {
					window.location.replace(sessionExpiredUrl);
				} else {
					navigate('/sign-in');
				}
			});
		}
	}, [mainState.token, navigate, setMainState]);

	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div className='flex items-center mt-2 md:mt-0'>
			<Languages />
			{isLoggedIn && <Notification />}
			{isLoggedIn && (
				<Popover
					placement={getUserLanguageInCookie() === 'ar' ? 'topLeft' : 'topRight'}
					trigger='click'
					open={isMenuOpen}
					onOpenChange={(newOpen) => setIsMenuOpen(newOpen)}
					content={
						<div className='flex flex-col'>
							<div className='user-menu-details'>
								<span className='user-menu-details-initials'>{getInitials(userMenuDetails.fullName)}</span>
								<div className='user-menu-details-content'>
									<div className='user-menu-name-container'>
										<p id='navbar-user-name' className='name'>
											{userMenuDetails.fullName}
										</p>
										{
											<img
												id='navbar-user-image'
												src={`https://flagsapi.com/${userMenuDetails.countryCode}/shiny/64.png`}
												alt='country'
												className='country-flag'
											/>
										}
									</div>
									<p id='navbar-user-email' className='email'>
										{userMenuDetails.email}
									</p>
								</div>
							</div>
							{isMerchant && (
								<Button
									id='navbar-button-profile'
									onClick={() => {
										setIsMenuOpen(false);
										navigate(`/merchant?id=${userInfo?.id}`);
									}}
									className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
								>
									{t<string>('myProfile')}
								</Button>
							)}
							<Button
								id='navbar-button-my-account'
								onClick={() => {
									setIsMenuOpen(false);
									navigate('/my-accounts');
								}}
								className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
							>
								{t<string>('myAccounts')}
							</Button>
							<Button
								id='navbar-button-wallet-accounts'
								onClick={() => {
									setIsMenuOpen(false);
									navigate('/wallet-accounts');
								}}
								className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
							>
								{isMerchant ? t<string>('MY_WALLETS') : t<string>('WALLET_ACCOUNTS')}
							</Button>
							<Button
								id='navbar-button-logout'
								onClick={() => {
									setIsMenuOpen(false);
									hanelLogOut();
								}}
								className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
							>
								{t<string>('logout')}
							</Button>
						</div>
					}
				>
					<Button
						id='navbar-button-user-dialog'
						className='p-0 m-0 border-none uppercase text-white text-xs font-medium flex items-center hover:text-white bg-transparent'
					>
						<UserOutlined style={{ fontSize: 16, color: secondary_color }} />
						<DownOutlined className='w-2' style={{ fontSize: 10, color: secondary_color }} />
					</Button>
				</Popover>
			)}
		</div>
	);
};

export default NavBarIcons;
