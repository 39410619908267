const DOMAIN =
	process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;

export const API_ENDPOINTS = {
	baseUrl: DOMAIN,
	refreshToken: `${DOMAIN}/api/refresh`,
	notifications: `${DOMAIN}/api/notifications/list`,
	notificationsCount: `${DOMAIN}/api/notifications/count/unread`,
	signin: `${DOMAIN}/api/login`,
	logout: `${DOMAIN}/api/logout`,
	countries: `${DOMAIN}/api/countries`,
	getMerchantOffer: `${DOMAIN}/api/offers/`,
	chat: `${DOMAIN}/ws/order-chat`,
	uploadFile: `${DOMAIN}/api/chat/file/upload/`,
	downloadFile: `${DOMAIN}/api/chat/file/download/`,
	offers: `${DOMAIN}/api/offers/`,
	myOffers: `${DOMAIN}/api/offers/my-offer/%type%`,
	livePirce: `${DOMAIN}/api/exchange-rates`,
	generalSupport: `${DOMAIN}/api/supportemail/general`,
	orderSupport: `${DOMAIN}/api/supportemail/order`,
	getChats: `${DOMAIN}/api/chat/%orderNumber%`,
	getFileUrl: `${DOMAIN}/api/chat/file/download/%orderNumber%/%fileName%`,
	merchant: `${DOMAIN}/api/merchant/%merchantId%`,
	merchantReviews: `${DOMAIN}/api/merchant/%merchantId%/reviews`,
	accounts: `${DOMAIN}/api/accounts`,
	wallet: `${DOMAIN}/api/wallet`,
	myOrders: `${DOMAIN}/api/orders/`,
	order: `${DOMAIN}/api/order/`,
	getOrderChatMsgTopic: '/topic/order/%orderNumber%/chat',
	getPublicNotificationTopic: `/topic/notification/public`,
	getUserNotificationTopic: `/topic/notification/user/%userId%`,
	getRoleNotificationTopic: `/topic/notification/role/%role%`,
	sendOrderChatMsgTopic: '/p2p/order-chat/%orderNumber%/sendMessage',
	clientOrderList: `${DOMAIN}/api/orders/client`,
	merchantOrderList: `${DOMAIN}/api/orders/merchant`,
	openOrder: `${DOMAIN}/api/orders`,
	canOpenOrder: `${DOMAIN}/api/canOpenOrder`,
	orderDetails: `${DOMAIN}/api/order/%side%/%orderNumber%`,
	orderAction: `${DOMAIN}/api/order/%orderNumber%/action/%action%`,
	review: `${DOMAIN}/api/merchant/review`,
	notification: `${DOMAIN}/ws/notification`,
	getOtp: `${DOMAIN}/api/order/otp/generate?orderNumber=%orderNumber%`,
	exhangePrice: `${DOMAIN}/api/exchangePrice`,
	getLimitReached: `${DOMAIN}/api/merchant/limitReached/%merchantId%`,
	getConfiguration: `${DOMAIN}/api/configuration/public/%configKey%`,
	uploadFilePaymentProof: `${DOMAIN}/api/upload/paymentproof/%orderNumber%`,
	downloadFilePaymentProof: `${DOMAIN}/api/download/paymentproof/%orderNumber%/%fileName%`,
	getAnnoncement: `${DOMAIN}/api/announcements?enabled=true`,
	walletTransactions: `${DOMAIN}/api/wallet-transactions`,
	myWallets: `${DOMAIN}/api/wallet/my-wallets`,
	operationReport: `${DOMAIN}/api/wallet/transaction/%operation%/%transactionNumber%`,
	walletTransaction: `${DOMAIN}/api/wallet-transaction`,
	getOtpTransaction: `${DOMAIN}/api/wallet-transaction/otp/generate?transactionNumber=%transactionNumber%`,
	verifyTransactionOtp: `${DOMAIN}/api/wallet-transaction/otp/validate?transactionNumber=%transactionNumber%&otp=%otp% `,
	getGeneralConfig: `${DOMAIN}/api/configuration/public`,
	redirectLogin: `${DOMAIN}/api/p2p/login`,
	currentUserStatus: `${DOMAIN}/api/users/current-status`,
	getTransactionImage: `${DOMAIN}/api/wallet-transactions/download/payment-proof/%transactionNumber%/%fileName%`,
	getRecaptchaConfig: `${DOMAIN}/api/recaptcha-config`,
};
