import React, { useMemo, useEffect, useState } from 'react';
import { Button, notification, Card, Row, Col, Menu } from 'antd';
import './Notification.scss';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import type { NotificationInstance, NotificationPlacement } from 'antd/es/notification/interface';
import { BellFilled, BellOutlined, InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { notificationContentGenerator } from 'helpers/notificationContent';
import { getNotificationType } from 'helpers/getNotificationType';
import { t } from 'i18next';
import NoNotificationsCard from './components/NoNotificationsCard';
import NotificationCard from './components/NotificationCard';
import useComponentVisible from './useComponentVisible';
import ReactTimeAgo from 'react-time-ago';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import { getLanguage } from 'helpers/getLanguage';
import { useDirectionContext } from 'store/DirectionContext';
import { useMainContext } from 'store/MainContext';
import { NotificationActionType } from 'helpers/getNotificationsActionTypes';
import { UserInfo } from 'models/UserInfo';
import { useNotificationTypeContext } from 'store/NotificationTypeContext';
import { useConfigurationContext } from 'store/configurationContext';

const Context = React.createContext({ name: 'Default' });

/**
 * Returns true if the passed notification should trigger a redirection to a specific location.
 *
 * @param {string} notificationType
 * @returns {boolean}
 */
const shouldRedirect = (notificationType: string): boolean =>
	[
		'NEW_ORDER',
		'ORDER_NEW_MESSAGE',
		'ORDER_EXPIRY_WARNING',
		'ORDER_EXPIRED',
		'ORDER_STATUS_UPDATE',
		'APPEAL_REASSIGNED',
	].includes(notificationType);

const getNotificationStyle = (action: string, isSmallScreen: boolean) => {
	const top = isSmallScreen ? '0px' : '50px';
	switch (action) {
		case 'WARNING':
			return {
				backgroundColor: '#fbe7c6',
				top,
			};
		case 'ACTION':
			return {
				backgroundColor: '#b7f7c4',
				top,
			};
		default:
			return {
				backgroundColor: '#b3e6f5',
				top,
			};
	}
};
const openNotification = (
	placement: NotificationPlacement,
	api: NotificationInstance,
	action: string,
	title: string,
	message: string,
	isSmallScreen: boolean,
	navigate?: NavigateFunction,
	redirect?: string,
) => {
	api.info({
		onClick: () => {
			if (navigate && redirect) {
				navigate(redirect);
			}
		},
		message: title,
		description: message,
		placement,
		icon:
			action === 'ACTION' ? (
				<BellFilled style={{ color: '#000000' }} />
			) : action === 'WARNING' ? (
				<WarningFilled style={{ color: '#000000' }} />
			) : (
				<InfoCircleFilled style={{ color: '#000000' }} />
			),
		style: {
			...getNotificationStyle(action, isSmallScreen),
		},
	});
};

const Notification: React.FC = () => {
	const location = useLocation();
	const { mainState, setMainState } = useMainContext();
	const { setNotificationType } = useNotificationTypeContext();
	const [api, contextHolder] = notification.useNotification({
		maxCount: 3,
	});
	const { configurationState } = useConfigurationContext();
	const contextValue = useMemo(() => ({ name: 'World' }), []);
	let [stompClient, setStompClient] = useState<Stomp.Client | null>(null);
	const token = getUserInfo()?.token;
	const userId = useMemo(() => getUserInfo()?.userId || '', []);
	const [notificationsCount, setNotificationsCount] = useState(0);
	const [notifications, setNotifications] = useState<Record<any, any>[]>([]);
	const [loading, setLoading] = useState(false);
	const playSound = (): Promise<void> => {
		const audio = new Audio('/sounds/Bell-Notification.mp3');
		return audio.play();
	};
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const getLoggedInUserRole = () => (isMerchant ? 'MERCHANT' : 'CLIENT');

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const navigate = useNavigate();
	const redirectUrl = (notification: any) => {
		switch (notification.notification) {
			case 'NEW_ORDER':
			case 'ORDER_NEW_MESSAGE':
			case 'ORDER_EXPIRY_WARNING':
			case 'ORDER_EXPIRED':
			case 'ORDER_STATUS_UPDATE':
			case 'APPEAL_REASSIGNED':
				return `/order-life-cycle?id=${notification.properties.orderNumber}`;
			case 'OFFER_DISABLED':
			case 'EXCHANGE_RATE_UPDATE':
				return '/my-offers';
			case 'NEW_OPERATION':
			case 'REQUEST_ACTION':
				return `/wallet?transactionNumber=${notification.properties.transactionNumber}&type=${notification.properties.type}`;
			default:
				return '/notifications';
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const triggerNotification = (type: string, title: string, body: string, messageObject: any) => {
		const redirectionLink = redirectUrl(messageObject);
		openNotification('topRight', api, type, title, body, windowWidth <= 500, navigate, redirectionLink);
	};

	const showNotifications = () => {
		const numberOfNotificationsInDropDown = 5;
		if (isComponentVisible === false) {
			setLoading(true);
			axios
				.get(`${API_ENDPOINTS.notifications}?size=${numberOfNotificationsInDropDown}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res: any) => {
					res.data.data = res.data.data.map((notification: any) => {
						return {
							...notification,
							avatar: 'https://example.com/avatar2.png',
							title: t<string>(notification.notification)
								.replace(/_/g, ' ')
								.replace(/\w\S*/g, (w: string) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()),
							timestamp: notification.createdAt ? (
								<ReactTimeAgo date={notification.createdAt} locale={getLanguage(getUserLanguageInCookie())} />
							) : null,
							content: notificationContentGenerator(notification.notification, {
								...notification.properties,
								createdAt: notification.createdAt,
							}),
							type: getNotificationType(notification.notification),
						};
					});
					setNotifications(res.data.data);
				})
				.finally(() => {
					setLoading(false);
					axios
						.get(API_ENDPOINTS.notificationsCount, {
							headers: {
								Authorization: `Bearer ${token}`,
							},
						})
						.then((res: any) => {
							setNotificationsCount(res.data);
						})
						.catch((err) => console.error(err));
				});
		}
		setIsComponentVisible(!isComponentVisible);
	};
	const topicSubscriptionCallback = async (msgReceived: any) => {
		if (getUserInfo()?.token) {
			playSound().catch((err) => console.error(err));
			let messageObject = JSON.parse(msgReceived.body);
			axios
				.get(API_ENDPOINTS.notificationsCount, {
					headers: {
						Authorization: `Bearer ${getUserInfo()?.token}`,
					},
				})
				.then((res: any) => {
					setNotificationsCount(res.data);
				})
				.catch((err) => console.error(err));
			const title = t<string>(messageObject.notification);
			const content = notificationContentGenerator(messageObject.notification, {
				...messageObject.properties,
				createdAt: messageObject.createdAt,
			});
			const notificationType = getNotificationType(messageObject.notification);
			triggerNotification(notificationType, title, content, messageObject);
			if (
				messageObject.notification === NotificationActionType.USER_BLOCK ||
				messageObject.notification === NotificationActionType.USER_SUSPEND ||
				messageObject.notification === NotificationActionType.USER_UNBLOCK ||
				messageObject.notification === NotificationActionType.USER_UNSUSPEND
			) {
				//TODO:: new api to get the current logged user status
				const userStatus = await axios.get(API_ENDPOINTS.currentUserStatus);
				const userInfo: any = { ...getUserInfo(), userStatus: userStatus.data.status };
				setUserInfo(userInfo);
				setMainState({ ...mainState, userStatus: userStatus.data.status });
			}
			setNotificationType(messageObject.notification);
		} else {
			stompClient?.disconnect(() => {
				return true;
			});
		}
	};

	const onConnected = (stompClient: Stomp.Client) => {
		const role = getLoggedInUserRole();
		const publicTopic = API_ENDPOINTS.getPublicNotificationTopic;
		const roleTopic = API_ENDPOINTS.getRoleNotificationTopic.replace('%role%', role);
		const userTopic = userId ? API_ENDPOINTS.getUserNotificationTopic.replace('%userId%', userId.toString()) : null;
		stompClient.subscribe(publicTopic, topicSubscriptionCallback);
		stompClient.subscribe(roleTopic, topicSubscriptionCallback);
		if (userTopic) {
			stompClient.subscribe(userTopic, topicSubscriptionCallback);
		}
	};

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	useEffect(() => {
		const socket = new SockJS(API_ENDPOINTS.notification);

		const connectToSocket = (token: string) => {
			const client = Stomp.over(socket);
			client.connect(
				{ Authorization: `Bearer ${token}` },
				() => {
					setStompClient(client);
					onConnected(client);
				},
				(err) => {
					console.error(err);
				},
			);
		};

		if (token && !stompClient) {
			connectToSocket(token);
		}

		// Cleanup logic
		return () => {
			if (stompClient) {
				stompClient?.disconnect(() => {
					return true;
				});
			}
		};
	}, [token, stompClient]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(API_ENDPOINTS.notificationsCount, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setNotificationsCount(response.data);
			} catch (error) {
				console.error(error);
			}
		};
		// Fetch data and connect to socket when the component mounts
		fetchData();
	}, []);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	let items =
		notifications.length === 0
			? [
					<>
						<NoNotificationsCard loading={loading} />
					</>,
			  ]
			: notifications.map((el: any, indx) => {
					return (
						<>
							<Link
								onClick={() => {
									setIsComponentVisible(false);
								}}
								to={redirectUrl(el)}
							>
								<NotificationCard
									key={indx}
									type={el.type}
									notification={el.notification}
									properties={el.properties}
									timestamp={el.timestamp}
								/>
							</Link>
							{indx === notifications.length - 1 ? (
								<Card className='view-more-notifications-card'>
									<Row className='flex justify-end' align='middle'>
										<Col className='notification-content '>
											<Link
												onClick={() => {
													setIsComponentVisible(false);
												}}
												to='/notifications'
											>
												<Button>{t('seeMore')}</Button>
											</Link>
										</Col>
									</Row>
								</Card>
							) : (
								<></>
							)}
						</>
					);
			  });
	return (
		<div
			className={
				location.pathname === '/notifications'
					? 'invisible bill-container flex flex-col '
					: ' bill-container flex flex-col'
			}
			ref={ref as React.RefObject<HTMLDivElement>}
		>
			<Button
				onClick={showNotifications}
				className='flex items-center p-0 mx-4 bg-transparent border-none text-white bill-button'
			>
				<BellOutlined style={{ fontSize: '20px', color: secondary_color }} />
				{notificationsCount && !isComponentVisible ? (
					<span className='bill-icon' style={{ backgroundColor: themeColor, color: secondary_color }}>
						{notificationsCount > 99 ? '+99' : notificationsCount}
					</span>
				) : (
					<span className='bill-icon-empty'></span>
				)}
			</Button>
			{isComponentVisible && <Menu className='notifications-dropdown'>{items}</Menu>}
			<Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
		</div>
	);
};

export default Notification;
