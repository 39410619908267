import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { WalletActionType } from '../types/walletActionType';

type WalletActionsProps = {
	className?: string;
	walletActionType: string;
	onAction: (action: WalletActionType) => void;
};

export const WalletActions = (props: WalletActionsProps) => {
	const { t } = useTranslation();

	return (
		<article className={`flex gap-2 ${props.className} `}>
			<Button
				htmlType='button'
				type='default'
				shape='round'
				className='xss:text-[10px] sm:text-sm xss:!ps-2 xss:!pe-2 md:!ps-4 md:!pe-4'
				style={{ backgroundColor: props.walletActionType === WalletActionType.WITHDRAW ? '#0096ff' : 'transparent' }}
				onClick={() => props.onAction(WalletActionType.WITHDRAW)}
			>
				{t('WITHDRAW_FROM_MT5')}
			</Button>
			<Button
				htmlType='button'
				type='default'
				shape='round'
				className='xss:text-[10px] sm:text-sm xss:!ps-2 xss:!pe-2 md:!ps-4 md:!pe-4'
				style={{ backgroundColor: props.walletActionType === WalletActionType.DEPOSIT ? '#0096ff' : 'transparent' }}
				onClick={() => props.onAction(WalletActionType.DEPOSIT)}
			>
				{t('DEPOSIT_TO_MT5')}
			</Button>
		</article>
	);
};
