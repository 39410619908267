import axios, { AxiosRequestConfig } from 'axios';
import { API_ENDPOINTS } from './endpoints';
import { getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import { notification } from 'antd';
import { getErrorMessage } from 'helpers/errorHandler';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from 'store/MainContext';
import { resetMainState } from 'assets/config/initial-main-state';
import React from 'react';

interface RetryQueueItem {
	resolve: (value?: any) => void;
	reject: (error?: any) => void;
	config: AxiosRequestConfig;
}

// Add a request interceptor
function AxiosWrapper({ children }: { children: React.ReactNode }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setMainState } = useMainContext();
	let isRefreshing = false;
	useLayoutEffect(() => {
		const requestInterceptor = axios.interceptors.request.use(
			(config) => {
				const traceId = uuidv4();
				if (!config?.url?.includes(API_ENDPOINTS.refreshToken) && !config?.url?.includes('configuration/public')) {
					// Set the "X-TraceId" header in the request config
					config.headers['x-TraceId'] = traceId;
					// Check if the request has an authorization header and a token
					const token = getUserInfo()?.token;
					if (token) {
						config.headers.Authorization = `Bearer ${token}`;
					}
				} else {
					delete config.headers.Authorization;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			},
		);

		// Add a response interceptor

		// Create a list to hold the request queue
		const refreshAndRetryQueue: RetryQueueItem[] = [];

		// Flag to prevent multiple token refresh requests

		const responseInterceptor = axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const originalRequest: AxiosRequestConfig = error.config;
				const errorCode = Number(error?.response?.data.code);
				const data = error?.response?.data?.extraAttributes ?? '';
				const errorMessage = getErrorMessage(errorCode, t, data);

				if (errorMessage && error.response.status !== 401 && errorCode !== 2043) {
					notification['error']({ message: '', description: errorMessage, placement: 'bottom' });
				}
				if (error.response && error.response.status === 401 && originalRequest.url?.includes('login')) {
					notification['error']({ message: '', description: errorMessage, placement: 'bottom' });
				}
				if (
					error.response &&
					error.response.status === 401 &&
					!originalRequest.url?.includes('login') &&
					!originalRequest.url?.includes('refresh')
				) {
					if (!isRefreshing) {
						isRefreshing = true;
						try {
							// Refresh the access token
							let refreshToken = JSON.parse(localStorage.getItem('userInfo') || '{}').refreshToken;
							const newAccessToken = await axios.post(API_ENDPOINTS.refreshToken, {
								refreshToken,
							});

							setUserInfo(newAccessToken.data);
							// Update the request headers with the new access token
							error.config.headers['Authorization'] = `Bearer ${newAccessToken.data.token}`;

							// Retry all requests in the queue with the new token
							refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
								axios
									.request(config)
									.then((response) => resolve(response))
									.catch((err) => reject(err));
							});

							// Clear the queue
							refreshAndRetryQueue.length = 0;

							// Retry the original request
							return axios(originalRequest);
						} catch (refreshError: any) {
							// Handle token refresh error
							// You can clear all storage and redirect the user to the login page
							notification['error']({
								message: getErrorMessage(refreshError?.response.data.code, t, ''),
								description: '',
								placement: 'bottom',
								key: refreshError?.response.data.code,
							});
							localStorage.clear();
							setMainState(resetMainState);
							navigate('/redirect');
						} finally {
							isRefreshing = false;
						}
					}

					// Add the original request to the queue
					return new Promise<void>((resolve, reject) => {
						refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
					});
				}

				// Return a Promise rejection if the status code is not 401
				return Promise.reject(error);
			},
		);
		return () => {
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		};
	}, [t]);
	return <>{children}</>;
}

export const MemoizedAxiosWrapper = React.memo(AxiosWrapper);
